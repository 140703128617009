import React from "react"
import { graphql } from "gatsby"
// import {Container, Row, Col} from "react-bootstrap"
import Seo from "../components/seo"

import Layout from "../components/layout"



export default function Project({ data }) {

      // CONTENT 
    const pageContent = data.allWpPage.nodes.map(({content}) => (<div dangerouslySetInnerHTML={{ __html: content }} />))
      
      //SEO THINGS
    const SeoTitle = data.allWpPage.nodes.map(({seoqueries}) => (seoqueries.title))
    const SeoDescription = data.allWpPage.nodes.map(({seoqueries}) => (seoqueries.description)) 
    const SeoKeywords = data.allWpPage.nodes.map(({seoqueries}) => (seoqueries.keywords))
      //END SEO THINGS

    return(
        <>
          <Seo 
            title = {SeoTitle}
            description = {SeoDescription}
            keywords = {SeoKeywords.toString()}
          />
          <Layout>
              {pageContent}
          </Layout>
        
        </>
    )
}




export const query = graphql`
  query(
    $slug: String!
    ) {
    allWpPage(filter: { slug: { eq: $slug }}) {
        nodes {
          slug
          content
          title
          seoqueries {
            title
            description
            keywords
          }
        }
      }
    }
    `